import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "forms"
    }}>{`Forms`}</h1>
    <p>{`At Tia we use `}<a parentName="p" {...{
        "href": "https://react-hook-form.com/"
      }}>{`react-hook-form`}</a>{` to manage form state and a smattering of `}<a parentName="p" {...{
        "href": "https://5efa84bae8e40500223e8b9e-bnaqzlpuwp.chromatic.com/?path=/story/forms--kitchen-sink-form"
      }}>{`custom Form components`}</a>{` to make life easier.`}</p>
    <p>{`The main benefit of RHF is that it's built on uncontrolled components. No re-renders for every keypress 🤘`}</p>
    <p>{`You shouldn't need to know details of RHF most of the time. The wiring is done for you inside TiaUI.`}</p>
    <p>{`Read more about how it works `}<a parentName="p" {...{
        "href": "https://swizec.com/blog/why-reacthookform-is-my-new-favorite-form-library"
      }}>{`in this article`}</a>{`.`}</p>
    <h2 {...{
      "id": "basic-use"
    }}>{`Basic use`}</h2>
    <p>{`A basic Tia form might look something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function YayForm() {
  // instantiates a form
  const formMethods = useForm()

  // what you wanna do with the data
  function onSubmit(data) {
    alert(JSON.stringify(data))
  }

  return (
    // context provider + wiring
    <Form onSubmit={onSubmit} useFormMethods={formMethods}>
      {/* plain text fields */}
      <Input name="firstName" />
      <Input name="lastName" />
      <Button type="submit">Click</Button>
    </Form>
  )
}
`}</code></pre>
    <h2 {...{
      "id": "exercise"
    }}>{`Exercise`}</h2>
    <p>{`No writable API for this workshop so we can't build a fancy form.`}</p>
    <p>{`Create a form to filter your list of dog breeds. Render an input element and add a submit button.`}</p>
    <p>{`You can use the `}<inlineCode parentName="p">{`useState`}</inlineCode>{` hook to set a filter when the form submits. Another option is to watch the form value directly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// with state
const [filter, setFilter] = useState("")

function filterBreeds(values: { filter: string }) {
  setFilter(values.filter)
}

// live reading from form
const filter = formMethods.watch("filter")
`}</code></pre>
    <p>{`Add `}<inlineCode parentName="p">{`staleTime: Infinity`}</inlineCode>{` as a config to your breeds query to avoid refetching. Breeds don't change often.`}</p>
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <p>{`You can find my solution at `}<a parentName="p" {...{
        "href": "https://github.com/AskTia/tia-react-train/tree/solution-forms"
      }}>{`https://github.com/AskTia/tia-react-train/tree/solution-forms`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      